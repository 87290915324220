<template>
  <v-app>
    <v-card flat width="400" class="mx-auto mt-5" :loading="loading">
      <v-card-title class="pb-0">
        <h1 class="my-16">התחבר</h1>
      </v-card-title>
      <v-card-text v-if="!loading">
        <v-form>
          <v-text-field v-model="email" prepend-icon="mdi-account" :type="'email'" filled rounded dense single-line>
            <template #label>
              <span class="red--text"><strong>* </strong></span>דואר
            </template>
          </v-text-field>
          <v-text-field v-model="password" :type="showPassword ? 'text' : 'password'" prepend-icon="mdi-lock"
            :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'" @click:append="showPassword = !showPassword" filled
            rounded dense single-line>
            <template #label>
              <span class="red--text"><strong>* </strong></span>סיסמה
            </template>
          </v-text-field>
          <router-link :to="'/forgot-password'" class="mx-1">שכחתי סיסמה</router-link>
        </v-form>
      </v-card-text>
      <!-- <v-divider></v-divider> -->
      <v-card-actions>
        <!-- <v-btn color="success" @click="registerUser">Register</v-btn> -->
        <v-btn @click="login" color="secondary" class="mr-11 px-13" elevation="0">התחבר</v-btn>
        <span class="mr-3"> עדיין לא רשום? </span>
        <router-link :to="'/register'" class="mx-1">הרשם</router-link>

      </v-card-actions>
    </v-card>
    <v-card flat width="400" class="mx-auto mt-5"> </v-card>
  </v-app>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  name: "App",
  data() {
    return {
      showPassword: false,
      email: "",
      password: "",
      name: "",
      userRole: "",
      bussinesNumber: null,
      secondEmail: "",
      phoneNumber: "",
    };
  },
  methods: {
    // async registerUser() {
    //   try {
    //     this.$router.push("/register");
    //     // await this.$store.dispatch("registerUser", {
    //     //   email: this.email,
    //     //   password: this.password,
    //     //   name: this.name,
    //     //   userRole: this.userRole,
    //     //   bussinesNumber: this.bussinesNumber,
    //     //   secondEmail: this.secondEmail,
    //     //   phoneNumber: this.phoneNumber
    //     // });
    //   } catch (error) {
    //     () => {};
    //   }
    // },
    async login() {
      try {
        await this.$store.dispatch("login", {
          email: this.email,
          password: this.password,
        });
        this.$router.push("/");
      } catch (error) {
        () => { };
      }
    },
    async getUserRoles() {
      this.$store.dispatch("loading", true);
      await this.$store.dispatch("getUserRoles");
      this.$store.dispatch("loading", false);
    },
  },
  // created() {
  //   this.getUserRoles();
  // },
  computed: {
    ...mapGetters(["loading"]),
  },
};
</script>